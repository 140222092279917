import { request } from '@core/api';
import type { Photo } from '@photos/types';
import { proxy } from 'valtio';
import { getPhotoClusters } from './helper';

export type Category = 'ALL' | string;

export interface Photos {
  showPhotos: boolean;
  showPreview: boolean;
  previewPhotos: Photo[];
  singles: Photo[];
  clusters: Photo[][];
  selectedCategory: Category;
  possibleCategories: Category[];
  currentPhotoIndex: number;
  isLoading: boolean;
}

export const photosState = proxy<Photos>({
  showPhotos: false,
  showPreview: false,
  previewPhotos: [],
  singles: [],
  clusters: [],
  currentPhotoIndex: 0,
  isLoading: false,
  selectedCategory: 'ALL',
  possibleCategories: ['ALL'],
});

export const togglePreview = (show = !photosState.showPreview): void => {
  photosState.showPreview = show;
};

export const setPhotoCategories = (categories: Category[]): void => {
  photosState.possibleCategories = ['ALL', ...categories.sort()];
};
export const setSelectedCategory = (category: Category): void => {
  photosState.selectedCategory = category;
};
export const setClustersAndSingles = (photos: Photo[]): void => {
  const thresholdInMeter = 1;
  const [singles, clusters] = getPhotoClusters(photos, thresholdInMeter);
  photosState.singles = singles;
  photosState.clusters = clusters;
};

export const togglePhotos = (show = !photosState.showPhotos): void => {
  photosState.showPhotos = show;
};

export const setPreviewPhotos = async (photos: Photo[]): Promise<void> => {
  photosState.currentPhotoIndex = 0;
  if (!photos?.length) photosState.previewPhotos = [];
  else {
    photosState.isLoading = true;
    photosState.previewPhotos = await Promise.all(
      photos.map((photo) => request<Photo>('GET', { path: photo.url })),
    );
    photosState.isLoading = false;
  }
};

export const showNextPhoto = (): void => {
  photosState.currentPhotoIndex =
    (photosState.currentPhotoIndex + 1) % photosState.previewPhotos.length;
};

export const openCurrentPhotoInNewTab = (): void => {
  if (!photosState.previewPhotos.length) return;
  window.open(photosState.previewPhotos[photosState.currentPhotoIndex].url, '_blank');
};
