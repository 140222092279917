import { request } from '@core/api';
import type { Photo } from '@photos/types';
import type { ScanFilters } from '@projects/pages/detail/hooks';
import { viewer3dSelectors } from '@viewer3D/redux';
import type { BoundingBoxWithHeight } from '@viewer3D/types';
import { useSelector } from 'react-redux';
import { useSnapshot } from 'valtio';
import { filterClustersAndSingles } from './helper';
import { type Photos, photosState, setClustersAndSingles, setPhotoCategories } from './state';

export const useConnect = ({
  viewport: bboxFilter,
  dateRange,
}: Pick<ScanFilters, 'dateRange' | 'viewport'>): {
  cameraViewport?: BoundingBoxWithHeight;
  photosInViewport: Pick<Photos, 'singles' | 'clusters'>;
  showPhotos: boolean;
  fetchAllPhotosForProject: () => void;
  projectId: string;
} => {
  const cameraViewport = useSelector(viewer3dSelectors.cameraViewport);
  const { showPhotos, selectedCategory, clusters, singles } = useSnapshot(photosState);

  const photosInViewport = filterClustersAndSingles(
    { clusters, singles },
    selectedCategory,
    bboxFilter ?? cameraViewport, // prefer bboxFilter to the camera viewport
    dateRange,
  );
  const projectId = useSelector(viewer3dSelectors.selectedProjectId) || '';

  return {
    showPhotos,
    cameraViewport,
    photosInViewport,
    projectId,
    fetchAllPhotosForProject: async () => {
      const photos = await request<Photo[]>('GET', { path: ['projects', projectId, 'photos'] });
      const allCategories = photos
        .filter((p) => p.category?.name)
        .map((photo) => photo.category?.name) as string[];
      setPhotoCategories([...new Set(allCategories)]);
      setClustersAndSingles(photos);
    },
  };
};
