import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { EnumSelect } from '@core/components';
import {
  photosState,
  setSelectedCategory,
  togglePhotos,
} from '@photos/components/PhotosInViewport/state';
import { useSnapshot } from 'valtio';

export const PhotosToggle = () => {
  const { showPhotos, possibleCategories, selectedCategory } = useSnapshot(photosState);

  return (
    <Stack direction="row">
      <FormControlLabel
        label="Photos"
        control={
          <Checkbox
            color="primary"
            checked={showPhotos}
            onChange={(event) => togglePhotos(event.target.checked)}
          />
        }
      />
      <EnumSelect
        label="Photo category"
        value={selectedCategory}
        onChange={(event) => setSelectedCategory(event.target.value as unknown as string)}
        options={possibleCategories}
      />
    </Stack>
  );
};
